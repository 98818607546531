// Override default variables before the import
$px-1: 1px;
$px-2: 2px;
$px-3: 3px;
$px-4: 4px;
$px-5: 5px;
$px-6: 6px;
$px-8: 8px;
$px-10: 10px;
$px-11: 11px;
$px-12: 12px;
$px-13: 13px;
$px-14: 14px;
$px-15: 15px;
$px-16: 16px;
$px-18: 18px;
$px-20: 20px;
$px-22: 22px;
$px-24: 24px;
$px-25: 25px;
$px-26: 26px;
$px-28: 28px;
$px-30: 30px;
$px-32: 32px;
$px-34: 34px;
$px-36: 36px;
$px-38: 38px;

/** font-size reset */
$font-size-base: 0.75rem !default;
$font-size-lg: $font-size-base * 1.25 !default;
$font-size-sm: $font-size-base * 0.875 !default;
$btn-line-height: 1.5 !default;

$sidebar-bg-color: #ffffff !default;
$sidebar-color: #252525 !default;
$sidebar-width: 230px !default;
$sidebar-collapsed-width: 80px !default;
$highlight-color: #777777 !default;
$submenu-bg-color: #ffffff !default;
$submenu-bg-color-collapsed: #2b2b2b !default;
$icon-bg-color: #2b2b2b !default;
$icon-size: 35px !default;

// import library styles
@import './color.scss';
@import '~bootstrap/scss/bootstrap.scss';
@import './default-sizing.scss';
@import '~react-toastify/dist/ReactToastify.css';
@import '~react-pro-sidebar/dist/scss/styles.scss';
@import './aimsis.scss';
@import './yss.scss';
