/**
 * Global Custom Aimsis Styling
 */

/** Navbar reset */
.navbar.navbar-aimsis.bg-light,
.navbar.navbar-aimsis-admin,
.button-dropdown-aimsis {
  background-color: #fff !important;
  border: none !important;
  box-shadow: 0 1px 6px 0 rgba(32, 33, 36, 0.28);

  .dropdown-menu {
    border-radius: 2px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border: none;
    margin-top: 8px !important;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);

    .dropdown-item {
      padding-top: 8px;
      padding-bottom: 8px;
    }
  }
}

.navbar.navbar-aimsis,
.navbar.navbar-aimsis-admin {
  .dropdown-menu {
    width: 300px;
  }

  .nav-link {
    padding: 0 6px !important;
  }

  .dropdown-toggle:after {
    content: none;
  }
}

.button-dropdown-aimsis {
  .dropdown-menu {
    margin-top: 0px !important;
  }
}

/** form control reset */
.form-control {
  box-shadow: none !important;
}

.form-group.required .form-label:after {
  content: ' *';
  color: red;
}

.input-group.input-login {
  .form-control.is-invalid {
    background-image: none;
  }

  .input-prepend-right {
    cursor: pointer;

    :hover {
      color: #7186ff;
    }

    .input-group-text {
      border-top-right-radius: 0.25rem;
      border-bottom-right-radius: 0.25rem;
      border-left: none !important;
    }

    .input-group-text.invalid {
      color: #dc3545;
      border: 1px solid #dc3545;
    }
  }

  .input-group-text {
    background-color: #fff;
    font-size: 1rem;
  }

  .input-group-prepend {
    .input-group-text.invalid {
      border: 1px solid #dc3545;
    }
  }
}

/** Button reset */
.btn.btn-primary.btn-login {
  background-color: $primary;
  background-image: linear-gradient(to right, #6cc0e7, #4e68ff);
  border: none;
  transition: all 0.5s ease;
}

.btn.btn-primary.btn-login:hover,
.btn.btn-primary.btn-login:active,
.btn.btn-primary.btn-login:visited {
  background-color: $primary;
  background-image: linear-gradient(to right, #5fbae4, #1739fa);
  border: none;
}

/** Material Design Box Shadow */
.shadow-1 {
  border: none !important;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}

.shadow-2 {
  border: none !important;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}

.shadow-3 {
  border: none !important;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.shadow-4 {
  border: none !important;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
}

.shadow-5 {
  border: none !important;
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
}

.shadow-6 {
  border: none !important;
  box-shadow: 0 19px 38px rgba(0, 0, 0, 0.3), 0 15px 12px rgba(0, 0, 0, 0.22);
}

/** Reset border style */
.border-none {
  border: none !important;
}

.b-r-none {
  border-right: none !important;
}

.b-l-none {
  border-left: none !important;
}

.b-t-none {
  border-top: none !important;
}

.b-b-none {
  border-bottom: none !important;
}

.b-y-none {
  border-top: none !important;
  border-bottom: none !important;
}

.b-x-none {
  border-right: none !important;
  border-left: none !important;
}

.b-radius-1 {
  border-radius: 6px;
}

.b-radius-2 {
  border-radius: 8px;
}

.b-radius-3 {
  border-radius: 12px;
}

.b-radius-4 {
  border-radius: 16px;
}

.b-radius-5 {
  border-radius: 20px;
}

.b-radius-6 {
  border-radius: 24px;
}

/** General classes */
.circle {
  border-radius: 2em;
}

.square {
  border-radius: 0 !important;
}

.cursor-pointer {
  cursor: pointer !important;
}

.primary-border {
  border: 1px solid $primary;
}

/** CK Editor Reset */
.ck-editor__editable_inline {
  min-height: 250px;
}

/** table custom */
.table-vertical-middle td {
  vertical-align: middle !important;
}

/** surveyjs custom */
.sv_q_required_text {
  color: red;
}
.sv-popup__container {
  width: auto !important;
  height: auto !important;
}

.width-auto {
  width: auto !important;
}
.inline-element {
  display: flex;
  justify-content: space-between;
}
