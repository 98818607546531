// Font size overide
.font {
  &-8 {
    font-size: $px-8 !important;
  }

  &-10 {
    font-size: $px-10 !important;
  }

  &-11 {
    font-size: $px-11 !important;
  }

  &-12 {
    font-size: $px-12 !important;
  }

  &-13 {
    font-size: $px-13 !important;
  }

  &-14 {
    font-size: $px-14 !important;
  }

  &-15 {
    font-size: $px-15 !important;
  }

  &-16 {
    font-size: $px-16 !important;
  }

  &-18 {
    font-size: $px-18 !important;
  }

  &-20 {
    font-size: $px-20 !important;
  }

  &-22 {
    font-size: $px-22 !important;
  }

  &-24 {
    font-size: $px-24 !important;
  }

  &-28 {
    font-size: $px-28 !important;
  }

  &-32 {
    font-size: $px-32 !important;
  }
}

// padding override
.p {
  &--1 {
    padding: $px-1;
  }

  &--2 {
    padding: $px-2;
  }

  &--3 {
    padding: $px-3;
  }

  &--4 {
    padding: $px-4;
  }

  &--5 {
    padding: $px-5;
  }

  &--6 {
    padding: $px-6;
  }

  &--8 {
    padding: $px-8;
  }

  &--10 {
    padding: $px-10;
  }

  &--11 {
    padding: $px-11;
  }

  &--12 {
    padding: $px-12;
  }

  &--13 {
    padding: $px-13;
  }

  &--14 {
    padding: $px-14;
  }

  &--15 {
    padding: $px-15;
  }

  &--16 {
    padding: $px-16;
  }

  &--18 {
    padding: $px-18;
  }

  &--20 {
    padding: $px-20;
  }

  &--22 {
    padding: $px-22;
  }

  &--24 {
    padding: $px-24;
  }

  &--25 {
    padding: $px-25;
  }

  &--26 {
    padding: $px-26;
  }

  &--28 {
    padding: $px-28;
  }

  &--30 {
    padding: $px-30;
  }

  &--32 {
    padding: $px-32;
  }

  &--34 {
    padding: $px-34;
  }

  &--36 {
    padding: $px-36;
  }

  &--38 {
    padding: $px-38;
  }
}

.p-t {
  &-1 {
    padding-top: $px-1;
  }

  &-2 {
    padding-top: $px-2;
  }

  &-3 {
    padding-top: $px-3;
  }

  &-4 {
    padding-top: $px-4;
  }

  &-5 {
    padding-top: $px-5;
  }

  &-6 {
    padding-top: $px-6;
  }

  &-8 {
    padding-top: $px-8;
  }

  &-10 {
    padding-top: $px-10;
  }

  &-11 {
    padding-top: $px-11;
  }

  &-12 {
    padding-top: $px-12;
  }

  &-13 {
    padding-top: $px-13;
  }

  &-14 {
    padding-top: $px-14;
  }

  &-15 {
    padding-top: $px-15;
  }

  &-16 {
    padding-top: $px-16;
  }

  &-18 {
    padding-top: $px-18;
  }

  &-20 {
    padding-top: $px-20;
  }

  &-22 {
    padding-top: $px-22;
  }

  &-24 {
    padding-top: $px-24;
  }

  &-25 {
    padding-top: $px-25;
  }

  &-26 {
    padding-top: $px-26;
  }

  &-28 {
    padding-top: $px-28;
  }

  &-30 {
    padding-top: $px-30;
  }

  &-32 {
    padding-top: $px-32;
  }

  &-34 {
    padding-top: $px-34;
  }

  &-36 {
    padding-top: $px-36;
  }

  &-38 {
    padding-top: $px-38;
  }
}

.p-b {
  &-1 {
    padding-bottom: $px-1;
  }

  &-2 {
    padding-bottom: $px-2;
  }

  &-3 {
    padding-bottom: $px-3;
  }

  &-4 {
    padding-bottom: $px-4;
  }

  &-5 {
    padding-bottom: $px-5;
  }

  &-6 {
    padding-bottom: $px-6;
  }

  &-8 {
    padding-bottom: $px-8;
  }

  &-10 {
    padding-bottom: $px-10;
  }

  &-11 {
    padding-bottom: $px-11;
  }

  &-12 {
    padding-bottom: $px-12;
  }

  &-13 {
    padding-bottom: $px-13;
  }

  &-14 {
    padding-bottom: $px-14;
  }

  &-15 {
    padding-bottom: $px-15;
  }

  &-16 {
    padding-bottom: $px-16;
  }

  &-18 {
    padding-bottom: $px-18;
  }

  &-20 {
    padding-bottom: $px-20;
  }

  &-22 {
    padding-bottom: $px-22;
  }

  &-24 {
    padding-bottom: $px-24;
  }

  &-25 {
    padding-bottom: $px-25;
  }

  &-26 {
    padding-bottom: $px-26;
  }

  &-28 {
    padding-bottom: $px-28;
  }

  &-30 {
    padding-bottom: $px-30;
  }

  &-32 {
    padding-bottom: $px-32;
  }

  &-34 {
    padding-bottom: $px-34;
  }

  &-36 {
    padding-bottom: $px-36;
  }

  &-38 {
    padding-bottom: $px-38;
  }
}

.p-l {
  &-1 {
    padding-left: $px-1;
  }

  &-2 {
    padding-left: $px-2;
  }

  &-3 {
    padding-left: $px-3;
  }

  &-4 {
    padding-left: $px-4;
  }

  &-5 {
    padding-left: $px-5;
  }

  &-6 {
    padding-left: $px-6;
  }

  &-8 {
    padding-left: $px-8;
  }

  &-10 {
    padding-left: $px-10;
  }

  &-11 {
    padding-left: $px-11;
  }

  &-12 {
    padding-left: $px-12;
  }

  &-13 {
    padding-left: $px-13;
  }

  &-14 {
    padding-left: $px-14;
  }

  &-15 {
    padding-left: $px-15;
  }

  &-16 {
    padding-left: $px-16;
  }

  &-18 {
    padding-left: $px-18;
  }

  &-20 {
    padding-left: $px-20;
  }

  &-22 {
    padding-left: $px-22;
  }

  &-24 {
    padding-left: $px-24;
  }

  &-25 {
    padding-left: $px-25;
  }

  &-26 {
    padding-left: $px-26;
  }

  &-28 {
    padding-left: $px-28;
  }

  &-30 {
    padding-left: $px-30;
  }

  &-32 {
    padding-left: $px-32;
  }

  &-34 {
    padding-left: $px-34;
  }

  &-36 {
    padding-left: $px-36;
  }

  &-38 {
    padding-left: $px-38;
  }
}

.p-r {
  &-1 {
    padding-right: $px-1;
  }

  &-2 {
    padding-right: $px-2;
  }

  &-3 {
    padding-right: $px-3;
  }

  &-4 {
    padding-right: $px-4;
  }

  &-5 {
    padding-right: $px-5;
  }

  &-6 {
    padding-right: $px-6;
  }

  &-8 {
    padding-right: $px-8;
  }

  &-10 {
    padding-right: $px-10;
  }

  &-11 {
    padding-right: $px-11;
  }

  &-12 {
    padding-right: $px-12;
  }

  &-13 {
    padding-right: $px-13;
  }

  &-14 {
    padding-right: $px-14;
  }

  &-15 {
    padding-right: $px-15;
  }

  &-16 {
    padding-right: $px-16;
  }

  &-18 {
    padding-right: $px-18;
  }

  &-20 {
    padding-right: $px-20;
  }

  &-22 {
    padding-right: $px-22;
  }

  &-24 {
    padding-right: $px-24;
  }

  &-25 {
    padding-right: $px-25;
  }

  &-26 {
    padding-right: $px-26;
  }

  &-28 {
    padding-right: $px-28;
  }

  &-30 {
    padding-right: $px-30;
  }

  &-32 {
    padding-right: $px-32;
  }

  &-34 {
    padding-right: $px-34;
  }

  &-36 {
    padding-right: $px-36;
  }

  &-38 {
    padding-right: $px-38;
  }
}

.p-y {
  &-1 {
    padding-top: $px-1;
    padding-bottom: $px-1;
  }

  &-2 {
    padding-top: $px-2;
    padding-bottom: $px-2;
  }

  &-3 {
    padding-top: $px-3;
    padding-bottom: $px-3;
  }

  &-4 {
    padding-top: $px-4;
    padding-bottom: $px-4;
  }

  &-5 {
    padding-top: $px-5;
    padding-bottom: $px-5;
  }

  &-6 {
    padding-top: $px-6;
    padding-bottom: $px-6;
  }

  &-8 {
    padding-top: $px-8;
    padding-bottom: $px-8;
  }

  &-10 {
    padding-top: $px-10;
    padding-bottom: $px-10;
  }

  &-11 {
    padding-top: $px-11;
    padding-bottom: $px-11;
  }

  &-12 {
    padding-top: $px-12;
    padding-bottom: $px-12;
  }

  &-13 {
    padding-top: $px-13;
    padding-bottom: $px-13;
  }

  &-14 {
    padding-top: $px-14;
    padding-bottom: $px-14;
  }

  &-15 {
    padding-top: $px-15;
    padding-bottom: $px-15;
  }

  &-16 {
    padding-top: $px-16;
    padding-bottom: $px-16;
  }

  &-18 {
    padding-top: $px-18;
    padding-bottom: $px-18;
  }

  &-20 {
    padding-top: $px-20;
    padding-bottom: $px-20;
  }

  &-22 {
    padding-top: $px-22;
    padding-bottom: $px-22;
  }

  &-24 {
    padding-top: $px-24;
    padding-bottom: $px-24;
  }

  &-25 {
    padding-top: $px-25;
    padding-bottom: $px-25;
  }

  &-26 {
    padding-top: $px-26;
    padding-bottom: $px-26;
  }

  &-28 {
    padding-top: $px-28;
    padding-bottom: $px-28;
  }

  &-30 {
    padding-top: $px-30;
    padding-bottom: $px-30;
  }

  &-32 {
    padding-top: $px-32;
    padding-bottom: $px-32;
  }

  &-34 {
    padding-top: $px-34;
    padding-bottom: $px-34;
  }

  &-36 {
    padding-top: $px-36;
    padding-bottom: $px-36;
  }

  &-38 {
    padding-top: $px-38;
    padding-bottom: $px-38;
  }
}

.p-x {
  &-1 {
    padding-left: $px-1;
    padding-right: $px-1;
  }

  &-2 {
    padding-left: $px-2;
    padding-right: $px-2;
  }

  &-3 {
    padding-left: $px-3;
    padding-right: $px-3;
  }

  &-4 {
    padding-left: $px-4;
    padding-right: $px-4;
  }

  &-5 {
    padding-left: $px-5;
    padding-right: $px-5;
  }

  &-6 {
    padding-left: $px-6;
    padding-right: $px-6;
  }

  &-8 {
    padding-left: $px-8;
    padding-right: $px-8;
  }

  &-10 {
    padding-left: $px-10;
    padding-right: $px-10;
  }

  &-11 {
    padding-left: $px-11;
    padding-right: $px-11;
  }

  &-12 {
    padding-left: $px-12;
    padding-right: $px-12;
  }

  &-13 {
    padding-left: $px-13;
    padding-right: $px-13;
  }

  &-14 {
    padding-left: $px-14;
    padding-right: $px-14;
  }

  &-15 {
    padding-left: $px-15;
    padding-right: $px-15;
  }

  &-16 {
    padding-left: $px-16;
    padding-right: $px-16;
  }

  &-18 {
    padding-left: $px-18;
    padding-right: $px-18;
  }

  &-20 {
    padding-left: $px-20;
    padding-right: $px-20;
  }

  &-22 {
    padding-left: $px-22;
    padding-right: $px-22;
  }

  &-24 {
    padding-left: $px-24;
    padding-right: $px-24;
  }

  &-25 {
    padding-left: $px-25;
    padding-right: $px-25;
  }

  &-26 {
    padding-left: $px-26;
    padding-right: $px-26;
  }

  &-28 {
    padding-left: $px-28;
    padding-right: $px-28;
  }

  &-30 {
    padding-left: $px-30;
    padding-right: $px-30;
  }

  &-32 {
    padding-left: $px-32;
    padding-right: $px-32;
  }

  &-34 {
    padding-left: $px-34;
    padding-right: $px-34;
  }

  &-36 {
    padding-left: $px-36;
    padding-right: $px-36;
  }

  &-38 {
    padding-left: $px-38;
    padding-right: $px-38;
  }
}

// margin override
.m {
  &--1 {
    margin: $px-1;
  }

  &--2 {
    margin: $px-2;
  }

  &--3 {
    margin: $px-3;
  }

  &--4 {
    margin: $px-4;
  }

  &--5 {
    margin: $px-5;
  }

  &--6 {
    margin: $px-6;
  }

  &--8 {
    margin: $px-8;
  }

  &--10 {
    margin: $px-10;
  }

  &--11 {
    margin: $px-11;
  }

  &--12 {
    margin: $px-12;
  }

  &--13 {
    margin: $px-13;
  }

  &--14 {
    margin: $px-14;
  }

  &--15 {
    margin: $px-15;
  }

  &--16 {
    margin: $px-16;
  }

  &--18 {
    margin: $px-18;
  }

  &--20 {
    margin: $px-20;
  }

  &--22 {
    margin: $px-22;
  }

  &--24 {
    margin: $px-24;
  }

  &--25 {
    margin: $px-25;
  }

  &--26 {
    margin: $px-26;
  }

  &--28 {
    margin: $px-28;
  }

  &--30 {
    margin: $px-30;
  }

  &--32 {
    margin: $px-32;
  }

  &--34 {
    margin: $px-34;
  }

  &--36 {
    margin: $px-36;
  }

  &--38 {
    margin: $px-38;
  }
}

.m-t {
  &-1 {
    margin-top: $px-1;
  }

  &-2 {
    margin-top: $px-2;
  }

  &-3 {
    margin-top: $px-3;
  }

  &-4 {
    margin-top: $px-4;
  }

  &-5 {
    margin-top: $px-5;
  }

  &-6 {
    margin-top: $px-6;
  }

  &-8 {
    margin-top: $px-8;
  }

  &-10 {
    margin-top: $px-10;
  }

  &-11 {
    margin-top: $px-11;
  }

  &-12 {
    margin-top: $px-12;
  }

  &-13 {
    margin-top: $px-13;
  }

  &-14 {
    margin-top: $px-14;
  }

  &-15 {
    margin-top: $px-15;
  }

  &-16 {
    margin-top: $px-16;
  }

  &-18 {
    margin-top: $px-18;
  }

  &-20 {
    margin-top: $px-20;
  }

  &-22 {
    margin-top: $px-22;
  }

  &-24 {
    margin-top: $px-24;
  }

  &-25 {
    margin-top: $px-25;
  }

  &-26 {
    margin-top: $px-26;
  }

  &-28 {
    margin-top: $px-28;
  }

  &-30 {
    margin-top: $px-30;
  }

  &-32 {
    margin-top: $px-32;
  }

  &-34 {
    margin-top: $px-34;
  }

  &-36 {
    margin-top: $px-36;
  }

  &-38 {
    margin-top: $px-38;
  }
}

.m-b {
  &-1 {
    margin-bottom: $px-1;
  }

  &-2 {
    margin-bottom: $px-2;
  }

  &-3 {
    margin-bottom: $px-3;
  }

  &-4 {
    margin-bottom: $px-4;
  }

  &-5 {
    margin-bottom: $px-5;
  }

  &-6 {
    margin-bottom: $px-6;
  }

  &-8 {
    margin-bottom: $px-8;
  }

  &-10 {
    margin-bottom: $px-10;
  }

  &-11 {
    margin-bottom: $px-11;
  }

  &-12 {
    margin-bottom: $px-12;
  }

  &-13 {
    margin-bottom: $px-13;
  }

  &-14 {
    margin-bottom: $px-14;
  }

  &-15 {
    margin-bottom: $px-15;
  }

  &-16 {
    margin-bottom: $px-16;
  }

  &-18 {
    margin-bottom: $px-18;
  }

  &-20 {
    margin-bottom: $px-20;
  }

  &-22 {
    margin-bottom: $px-22;
  }

  &-24 {
    margin-bottom: $px-24;
  }

  &-25 {
    margin-bottom: $px-25;
  }

  &-26 {
    margin-bottom: $px-26;
  }

  &-28 {
    margin-bottom: $px-28;
  }

  &-30 {
    margin-bottom: $px-30;
  }

  &-32 {
    margin-bottom: $px-32;
  }

  &-34 {
    margin-bottom: $px-34;
  }

  &-36 {
    margin-bottom: $px-36;
  }

  &-38 {
    margin-bottom: $px-38;
  }
}

.m-l {
  &-1 {
    margin-left: $px-1;
  }

  &-2 {
    margin-left: $px-2;
  }

  &-3 {
    margin-left: $px-3;
  }

  &-4 {
    margin-left: $px-4;
  }

  &-5 {
    margin-left: $px-5;
  }

  &-6 {
    margin-left: $px-6;
  }

  &-8 {
    margin-left: $px-8;
  }

  &-10 {
    margin-left: $px-10;
  }

  &-11 {
    margin-left: $px-11;
  }

  &-12 {
    margin-left: $px-12;
  }

  &-13 {
    margin-left: $px-13;
  }

  &-14 {
    margin-left: $px-14;
  }

  &-15 {
    margin-left: $px-15;
  }

  &-16 {
    margin-left: $px-16;
  }

  &-18 {
    margin-left: $px-18;
  }

  &-20 {
    margin-left: $px-20;
  }

  &-22 {
    margin-left: $px-22;
  }

  &-24 {
    margin-left: $px-24;
  }

  &-25 {
    margin-left: $px-25;
  }

  &-26 {
    margin-left: $px-26;
  }

  &-28 {
    margin-left: $px-28;
  }

  &-30 {
    margin-left: $px-30;
  }

  &-32 {
    margin-left: $px-32;
  }

  &-34 {
    margin-left: $px-34;
  }

  &-36 {
    margin-left: $px-36;
  }

  &-38 {
    margin-left: $px-38;
  }
}

.m-r {
  &-1 {
    margin-right: $px-1;
  }

  &-2 {
    margin-right: $px-2;
  }

  &-3 {
    margin-right: $px-3;
  }

  &-4 {
    margin-right: $px-4;
  }

  &-5 {
    margin-right: $px-5;
  }

  &-6 {
    margin-right: $px-6;
  }

  &-8 {
    margin-right: $px-8;
  }

  &-10 {
    margin-right: $px-10;
  }

  &-11 {
    margin-right: $px-11;
  }

  &-12 {
    margin-right: $px-12;
  }

  &-13 {
    margin-right: $px-13;
  }

  &-14 {
    margin-right: $px-14;
  }

  &-15 {
    margin-right: $px-15;
  }

  &-16 {
    margin-right: $px-16;
  }

  &-18 {
    margin-right: $px-18;
  }

  &-20 {
    margin-right: $px-20;
  }

  &-22 {
    margin-right: $px-22;
  }

  &-24 {
    margin-right: $px-24;
  }

  &-25 {
    margin-right: $px-25;
  }

  &-26 {
    margin-right: $px-26;
  }

  &-28 {
    margin-right: $px-28;
  }

  &-30 {
    margin-right: $px-30;
  }

  &-32 {
    margin-right: $px-32;
  }

  &-34 {
    margin-right: $px-34;
  }

  &-36 {
    margin-right: $px-36;
  }

  &-38 {
    margin-right: $px-38;
  }
}

.m-y {
  &-1 {
    margin-top: $px-1;
    margin-bottom: $px-1;
  }

  &-2 {
    margin-top: $px-2;
    margin-bottom: $px-2;
  }

  &-3 {
    margin-top: $px-3;
    margin-bottom: $px-3;
  }

  &-4 {
    margin-top: $px-4;
    margin-bottom: $px-4;
  }

  &-5 {
    margin-top: $px-5;
    margin-bottom: $px-5;
  }

  &-6 {
    margin-top: $px-6;
    margin-bottom: $px-6;
  }

  &-8 {
    margin-top: $px-8;
    margin-bottom: $px-8;
  }

  &-10 {
    margin-top: $px-10;
    margin-bottom: $px-10;
  }

  &-11 {
    margin-top: $px-11;
    margin-bottom: $px-11;
  }

  &-12 {
    margin-top: $px-12;
    margin-bottom: $px-12;
  }

  &-13 {
    margin-top: $px-13;
    margin-bottom: $px-13;
  }

  &-14 {
    margin-top: $px-14;
    margin-bottom: $px-14;
  }

  &-15 {
    margin-top: $px-15;
    margin-bottom: $px-15;
  }

  &-16 {
    margin-top: $px-16;
    margin-bottom: $px-16;
  }

  &-18 {
    margin-top: $px-18;
    margin-bottom: $px-18;
  }

  &-20 {
    margin-top: $px-20;
    margin-bottom: $px-20;
  }

  &-22 {
    margin-top: $px-22;
    margin-bottom: $px-22;
  }

  &-24 {
    margin-top: $px-24;
    margin-bottom: $px-24;
  }

  &-25 {
    margin-top: $px-25;
    margin-bottom: $px-25;
  }

  &-26 {
    margin-top: $px-26;
    margin-bottom: $px-26;
  }

  &-28 {
    margin-top: $px-28;
    margin-bottom: $px-28;
  }

  &-30 {
    margin-top: $px-30;
    margin-bottom: $px-30;
  }

  &-32 {
    margin-top: $px-32;
    margin-bottom: $px-32;
  }

  &-34 {
    margin-top: $px-34;
    margin-bottom: $px-34;
  }

  &-36 {
    margin-top: $px-36;
    margin-bottom: $px-36;
  }

  &-38 {
    margin-top: $px-38;
    margin-bottom: $px-38;
  }
}

.m-x {
  &-1 {
    margin-left: $px-1;
    margin-right: $px-1;
  }

  &-2 {
    margin-left: $px-2;
    margin-right: $px-2;
  }

  &-3 {
    margin-left: $px-3;
    margin-right: $px-3;
  }

  &-4 {
    margin-left: $px-4;
    margin-right: $px-4;
  }

  &-5 {
    margin-left: $px-5;
    margin-right: $px-5;
  }

  &-6 {
    margin-left: $px-6;
    margin-right: $px-6;
  }

  &-8 {
    margin-left: $px-8;
    margin-right: $px-8;
  }

  &-10 {
    margin-left: $px-10;
    margin-right: $px-10;
  }

  &-11 {
    margin-left: $px-11;
    margin-right: $px-11;
  }

  &-12 {
    margin-left: $px-12;
    margin-right: $px-12;
  }

  &-13 {
    margin-left: $px-13;
    margin-right: $px-13;
  }

  &-14 {
    margin-left: $px-14;
    margin-right: $px-14;
  }

  &-15 {
    margin-left: $px-15;
    margin-right: $px-15;
  }

  &-16 {
    margin-left: $px-16;
    margin-right: $px-16;
  }

  &-18 {
    margin-left: $px-18;
    margin-right: $px-18;
  }

  &-20 {
    margin-left: $px-20;
    margin-right: $px-20;
  }

  &-22 {
    margin-left: $px-22;
    margin-right: $px-22;
  }

  &-24 {
    margin-left: $px-24;
    margin-right: $px-24;
  }

  &-25 {
    margin-left: $px-25;
    margin-right: $px-25;
  }

  &-26 {
    margin-left: $px-26;
    margin-right: $px-26;
  }

  &-28 {
    margin-left: $px-28;
    margin-right: $px-28;
  }

  &-30 {
    margin-left: $px-30;
    margin-right: $px-30;
  }

  &-32 {
    margin-left: $px-32;
    margin-right: $px-32;
  }

  &-34 {
    margin-left: $px-34;
    margin-right: $px-34;
  }

  &-36 {
    margin-left: $px-36;
    margin-right: $px-36;
  }

  &-38 {
    margin-left: $px-38;
    margin-right: $px-38;
  }
}
